import { FormControlLabel, Radio as MuiRadio, RadioGroup } from "@mui/material";
import BaseController from "../BaseController/BaseController";

const CustomRadio = ({ row, options, labelId, field: { onChange, value } }) => {
  return (
    <RadioGroup
      row={row}
      value={value}
      onChange={onChange}
      aria-labelledby={labelId}
    >
      {options?.map(({ value, label }) => {
        return (
          <FormControlLabel
            key={value}
            value={value}
            label={label}
            control={<MuiRadio />}
          />
        );
      })}
    </RadioGroup>
  );
};

const Radio = ({
  row,
  id,
  name,
  label,
  options,
  disableBottomGutter = false,
}) => {
  return (
    <BaseController
      row={row}
      id={id}
      name={name}
      label={label}
      options={options}
      disableBottomGutter={disableBottomGutter}
      renderField={CustomRadio}
    />
  );
};

export default Radio;
