import CalendarIcon from "@mui/icons-material/Event";
import { DateTimeField, MobileDateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import BaseController from "../BaseController/BaseController";

const CustomDatePicker = ({
  placeholder,
  error,
  labelId,
  disabled,
  field: { onChange, value, ref },
}) => {
  const tmpValue = value ? dayjs(value) : null;

  const handleChange = (dateObject) => {
    onChange?.(dateObject?.$d ?? null);
  };

  return (
    <MobileDateTimePicker
      ampm={false}
      disablePast
      value={tmpValue}
      onChange={handleChange}
      inputRef={ref}
      slots={{ field: DateTimeField }}
      disabled={disabled}
      slotProps={{
        field: {
          "aria-labelledby": labelId,
          size: "small",
          fullWidth: true,
          format: "DD.MM.YYYY HH:mm",
          placeholder,
          error,
          readOnly: true,
          inputProps: {
            className: "date-picker-field",
          },
          InputProps: {
            endAdornment: <CalendarIcon />,
          },
        },
      }}
    />
  );
};

const DatePicker = ({
  id,
  name,
  label,
  placeholder,
  disabled,
  disableBottomGutter,
}) => {
  return (
    <BaseController
      id={id}
      name={name}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      disableBottomGutter={disableBottomGutter}
      renderField={CustomDatePicker}
    />
  );
};

export default DatePicker;
