import dayjs from "dayjs";

const LAST_MINUTE_HOURS_LIMIT = 72;

const GROSS_PRICE = 45;
const GROSS_PRICE_LAST_MINUTE = 60;
const PRICE_TAX = 21;

const getNetPrice = (price) => price - price * (PRICE_TAX / 100);

const getDateDiffInHours = (start, end) =>
  dayjs(end).diff(dayjs(start), "hours");

const getIsLastMinute = (date) => {
  return getDateDiffInHours(dayjs(), date) <= LAST_MINUTE_HOURS_LIMIT;
};

const getPriceByAmount = (price, amount) => {
  if (!price || !amount) return 0;

  return price * amount;
};

export const getPrices = (date, numberOfReferees = 1) => {
  const isLastMinute = getIsLastMinute(date);

  const grossPrice = isLastMinute ? GROSS_PRICE_LAST_MINUTE : GROSS_PRICE;
  const netPrice = getNetPrice(grossPrice);

  return {
    netPrice: getPriceByAmount(netPrice, numberOfReferees).toFixed(2),
    grossPrice: getPriceByAmount(grossPrice, numberOfReferees).toFixed(2),
  };
};
