import { Box, FormLabel, Typography, styled } from "@mui/material";

const styleConstants = {
  textLeft: "4px",
};

const customProps = ["disableBottomGutter"];
const shouldForwardProp = (propName) => !customProps.includes(propName);

export const BaseControllerStyled = styled(Box, {
  shouldForwardProp,
})(({ disableBottomGutter }) => {
  return {
    position: "relative",
    width: "100%",
    paddingBottom: disableBottomGutter ? "22px" : "30px",
  };
});

export const LabelStyled = styled(FormLabel)(() => {
  return {
    marginLeft: styleConstants.textLeft,
  };
});

export const ErrorMessageWrapper = styled(Box, {
  shouldForwardProp,
})(({ disableBottomGutter }) => {
  return {
    position: "absolute",
    bottom: disableBottomGutter ? 0 : "8px",
  };
});

export const ErrorMessage = styled(Typography)(() => {
  return {
    marginLeft: styleConstants.textLeft,
  };
});
ErrorMessage.defaultProps = {
  color: "error",
};
