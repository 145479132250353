import { Controller, useFormContext } from "react-hook-form";
import { getErrorsByName } from "../../helpers";
import {
  BaseControllerStyled,
  ErrorMessage,
  ErrorMessageWrapper,
  LabelStyled,
} from "./BaseController.style";

const generateLabelId = (id) => id + "-label";

const BaseController = ({
  renderField: RenderField = () => <div />,
  disableBottomGutter = false,
  disableRootLabel = false,
  ...rest
}) => {
  const { control, formState } = useFormContext();

  const errorMessage = getErrorsByName(formState?.errors, rest?.name);

  return (
    <BaseControllerStyled disableBottomGutter={disableBottomGutter}>
      {rest?.label && !disableRootLabel && (
        <LabelStyled id={generateLabelId(rest?.id)}>{rest?.label}</LabelStyled>
      )}

      <Controller
        control={control}
        name={rest?.name}
        render={(props) => (
          <RenderField
            {...props}
            {...rest}
            error={!!errorMessage}
            labelId={generateLabelId(rest?.id)}
            label={rest?.label}
          />
        )}
      />

      <ErrorMessageWrapper disableBottomGutter={disableBottomGutter}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ErrorMessageWrapper>
    </BaseControllerStyled>
  );
};

export default BaseController;
