import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import LoadingButton from "src/components/LoadingButton/LoadingButton";
import useFetcher from "src/hooks/useFetcher";
import useMutation from "src/hooks/useMutations";
import {
  RefereeRequestLink,
  RefereeRequestParagraph,
  RefereeRequestParagraphWrapper,
} from "src/pages/RefereeRequest/RefereeRequest.style";
import { getClubs } from "src/services/club";
import { setPreorder } from "src/services/order";
import { getClubTeams } from "src/services/team";
import Form from "../../Form";
import BaseController from "../../components/BaseController/BaseController";
import CheckboxTest from "../../components/Checkbox/Checkbox";
import DatePicker from "../../components/DatePicker/DatePicker";
import FormRow from "../../components/FormRow/FormRow";
import Input from "../../components/Input/Input";
import Radio from "../../components/Radio/Radio";
import Select from "../../components/Select/Select";
import TextArea from "../../components/TextArea/TextArea";
import { getPrices } from "./constants";
import {
  FORM_DEFAULT_VALUES,
  refereeRequestFormSchemaResolver,
} from "./refereeRequestForm.config";
import { object } from "yup";
import dayjs from "dayjs";

const RefereeRequestForm = () => {
  const { t } = useTranslation();

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    delayError: 1300,
    defaultValues: FORM_DEFAULT_VALUES,
    resolver: refereeRequestFormSchemaResolver,
  });

  const { mutation: handleSubmitPreorder, isLoading: isLoadingPreorder } =
    useMutation(setPreorder);

  const watchGameTime = methods.watch("matchup.gameTime");
  const watchNumberOfReferees = methods.watch("matchup.numberOfReferees");
  const pricing = useMemo(
    () => getPrices(watchGameTime, watchNumberOfReferees),
    [watchGameTime, watchNumberOfReferees]
  );

  const { data: clubOptions } = useFetcher({
    fn: getClubs,
  });

  // your club
  const yourClubFieldPath = "personalData.info.team.club.id";
  const selectedYourClub = methods.watch(yourClubFieldPath);
  const { data: yourTeamOptions } = useFetcher({
    fn: getClubTeams,
    fnProps: { clubId: selectedYourClub },
    skip: !selectedYourClub,
  });
  useEffect(() => {
    methods.resetField(yourClubFieldPath.replace(".club", ""));
  }, [methods, yourTeamOptions]);

  // home club
  const homeClubFieldPath = "matchup.home.team.club.id";
  const selectedHomeClub = methods.watch(homeClubFieldPath);
  const { data: homeTeamOptions } = useFetcher({
    fn: getClubTeams,
    fnProps: { clubId: selectedHomeClub },
    skip: !selectedHomeClub,
  });
  useEffect(() => {
    methods.resetField(homeClubFieldPath.replace(".club", ""));
  }, [methods, homeTeamOptions]);

  // away club
  const awayClubFieldPath = "matchup.away.team.club.id";
  const selectedAwayClub = methods.watch("matchup.away.team.club.id");
  const { data: awayTeamOptions } = useFetcher({
    fn: getClubTeams,
    fnProps: { clubId: selectedAwayClub },
    skip: !selectedAwayClub,
  });
  useEffect(() => {
    methods.resetField(awayClubFieldPath.replace(".club", ""));
  }, [methods, awayTeamOptions]);

  // submit
  const onSubmit = async (formData) => {

    const payload = {...formData, matchup:{...formData.matchup, gameTime:dayjs(formData.matchup.gameTime).format("YYYY-MM-DDTHH:mm:ss") }};
    // console.log(formData, payload);

    const onSuccess = (response) => {
      if (response?.paymentUrl) {
        window.location.href = response?.paymentUrl;
      }
    };

    const onError = (error) => {
      toast.error(t(`messages:error.api.${error.status}`));
    };

    await handleSubmitPreorder({
      payload,
      onSuccess,
      onError,
    });
  };

  const refereeCountOptions = [
    {
      value: "1",
      label: t("field.referee.plural_counter", { count: 1 }),
    },
    {
      value: "2",
      label: t("field.referee.plural_counter", { count: 2 }),
    },
  ];

  const SectionTitle = ({ title }) => {
    return (
      <Typography variant="h5" mb="6px">
        {title}
      </Typography>
    );
  };

  const AgreementAnchor = useMemo(
    () => (
      <RefereeRequestLink
        href="http://www.iklaatfluiten.nl/algemene-voorwaarden/"
        target="_blank"
      />
    ),
    []
  );

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <SectionTitle title={t("global.personalData")} />
      <Input
        id="fullName"
        name="personalData.info.name"
        label={t("field.name.full")}
      />

      <Input
        id="email"
        name="personalData.info.email"
        label={t("field.email.main")}
      />

      <Input
        id="phoneNumber"
        name="personalData.info.phoneNumber"
        label={t("field.phone.main")}
      />

      <FormRow col={2} label={t("field.clubAndTeam.your")}>
        <Select
          id="yourClub"
          name="personalData.info.team.club.id"
          placeholder={t("field.placeholder.club")}
          options={clubOptions}
        />

        <Select
          id="yourTeam"
          name="personalData.info.team.id"
          placeholder={t("field.placeholder.team")}
          options={yourTeamOptions}
          disabled={!selectedYourClub}
        />
      </FormRow>

      <SectionTitle title={t("global.matchData")} />
      <DatePicker
        id="gameTime"
        name="matchup.gameTime"
        label={t("field.date.match.start")}
        placeholder={t("field.placeholder.date.selectDateTime")}
      />

      <FormRow col={2} label={t("field.clubAndTeam.home")}>
        <Select
          id="homeClub"
          name="matchup.home.team.club.id"
          placeholder={t("field.placeholder.club")}
          options={clubOptions}
        />

        <Select
          id="homeTeam"
          name="matchup.home.team.id"
          placeholder={t("field.placeholder.team")}
          options={homeTeamOptions}
          disabled={!selectedHomeClub}
        />
      </FormRow>

      <FormRow col={2} label={t("field.clubAndTeam.away")}>
        <Select
          id="awayClub"
          name="matchup.away.team.club.id"
          placeholder={t("field.placeholder.club")}
          options={clubOptions}
        />

        <Select
          id="awayTeam"
          name="matchup.away.team.id"
          placeholder={t("field.placeholder.team")}
          options={awayTeamOptions}
          disabled={!selectedAwayClub}
        />
      </FormRow>

      <Input id="field" name="matchup.field" label={t("field.field.main")} />

      <Radio
        row
        id="numberOfReferees"
        name="matchup.numberOfReferees"
        label={t("field.referee.numberOfReferees")}
        options={refereeCountOptions}
        disableBottomGutter
      />

      <BaseController
        id="price"
        name="price"
        label={t("global.price.main")}
        disableBottomGutter
        renderField={({ labelId }) => (
          <Typography aria-labelledby={labelId} fontWeight="bold">
            {t("global.price.text", {
              sign: "€",
              ...pricing,
            })}
          </Typography>
        )}
      />

      <TextArea
        id="comment"
        name="matchup.comment"
        label={t("field.comments")}
      />

      <RefereeRequestParagraphWrapper
        sx={{ marginTop: 0, marginBottom: "20px" }}
      >
        <RefereeRequestParagraph>
          {t("refereeRequest.paragraph3")}
        </RefereeRequestParagraph>
        <RefereeRequestParagraph>
          {t("refereeRequest.paragraph4")}
        </RefereeRequestParagraph>
        <RefereeRequestParagraph>
          {t("refereeRequest.paragraph5")}
        </RefereeRequestParagraph>
      </RefereeRequestParagraphWrapper>

      <CheckboxTest
        id="accept-agreement"
        name="acceptAgreement"
        label={
          <Trans
            i18nKey="field.acceptAgreement"
            components={{
              accept_anchor: AgreementAnchor,
            }}
          />
        }
      />

      <Form.ActionsWrapper>
        <LoadingButton type="submit" loading={isLoadingPreorder}>
          {t("actions.submit")}
        </LoadingButton>
      </Form.ActionsWrapper>
    </Form>
  );
};

export default RefereeRequestForm;
