import { Autocomplete, TextField } from "@mui/material";
import BaseController from "../BaseController/BaseController";

const CustomSelect = ({
  id,
  labelId,
  options,
  disabled,
  placeholder,
  error,
  field: { onChange, onBlur, value = "", ref },
}) => {
  const handleChange = (_, selectedOption) => {
    onChange?.(selectedOption?.id);
  };

  return (
    <Autocomplete
      id={id}
      aria-labelledby={labelId}
      label={null}
      size="small"
      fullWidth
      disablePortal
      disableClearable
      options={options || []}
      disabled={disabled}
      value={value || null}
      onBlur={onBlur}
      onChange={handleChange}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            inputRef={ref}
            placeholder={placeholder}
            error={error}
          />
        );
      }}
      getOptionLabel={(option) => {
        let tmpOption = option;

        if (["string", "number"].includes(typeof tmpOption)) {
          tmpOption = options?.find((opt) => opt.id === option);
        }

        return tmpOption?.name ?? "";
      }}
      isOptionEqualToValue={(option, value) => {
        return option.id === value;
      }}
    />
  );
};

const Select = ({
  options = [],
  id,
  name,
  disabled,
  label,
  placeholder,
  disableBottomGutter,
}) => {
  return (
    <BaseController
      id={id}
      name={name}
      label={label}
      disableBottomGutter={disableBottomGutter}
      options={options}
      disabled={disabled}
      placeholder={placeholder}
      renderField={CustomSelect}
    />
  );
};

export default Select;
