import { TextField } from "@mui/material";
import BaseController from "../BaseController/BaseController";

const CustomTextArea = ({
  id,
  rows,
  error,
  labelId,
  field: { onChange, onBlur, value = "", ref },
}) => {
  return (
    <TextField
      multiline
      rows={rows}
      fullWidth
      size="small"
      inputRef={ref}
      id={id}
      value={value}
      aria-labelledby={labelId}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
    />
  );
};

const TextArea = ({ id, name, label, rows = 3, disableBottomGutter }) => {
  return (
    <BaseController
      rows={rows}
      id={id}
      name={name}
      label={label}
      disableBottomGutter={disableBottomGutter}
      renderField={CustomTextArea}
    />
  );
};

export default TextArea;
