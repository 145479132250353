import { Checkbox, FormControlLabel, useTheme } from "@mui/material";
import BaseController from "../BaseController/BaseController";

const CustomCheckbox = ({
  id,
  error,
  label,
  field: { onChange, onBlur, value = false, ref },
}) => {
  const theme = useTheme();
  const color = error ? theme.palette.error.main : theme.palette.primary.main;

  const customStyle = {
    color,
    "&.Mui-checked": {
      color,
    },
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          inputRef={ref}
          id={id}
          checked={value}
          onChange={onChange}
          onBlur={onBlur}
          sx={customStyle}
        />
      }
      label={label}
    />
  );
};

const CheckboxTest = ({ id, name, label, disableBottomGutter }) => {
  return (
    <BaseController
      id={id}
      name={name}
      label={label}
      disableBottomGutter={disableBottomGutter}
      disableRootLabel
      renderField={CustomCheckbox}
    />
  );
};

export default CheckboxTest;
