import { useTranslation } from "react-i18next";
import Napoleon from "src/assets/images/logos/napoleon.gif";

const RefereeRequestSuccess = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "refereeRequestSuccess",
  });

  return <div style={{
    position: 'absolute', 
    left: '50%', 
    top: '40%',
    transform: 'translate(-50%, -50%)'}}>
      <h2>{t("title")}</h2>
      <img src={Napoleon}/>
      <p>{t("paragraph1")}</p>
  </div>;
};

export default RefereeRequestSuccess;
