import { lighten } from "@mui/material";

const components = {
  MuiButton: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        const containedStyle = getConditionStyle(
          ownerState?.variant === "contained",
          {
            fontWeight: 600,
            fontSize: "15px",
            borderRadius: "5px",
            border: "2px solid transparent",
            boxShadow: "none",
            ":hover": {
              backgroundColor: theme.palette.common.white,
              color: theme.palette.primary.main,
              borderColor: theme.palette.primary.main,
              boxShadow: "none",
            },
          }
        );

        return {
          ...containedStyle,
        };
      },
    },
  },

  MuiFormControl: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        const disabledStyle = getConditionStyle(ownerState?.disabled, {
          "*": {
            cursor: "not-allowed !important",
            borderColor: `${getProperTextColor(ownerState, theme)} !important`,
          },
        });

        return { ...disabledStyle };
      },
    },
  },

  MuiInputBase: {
    styleOverrides: {
      root: ({ ownerState, theme }) => {
        const datePickerFieldStyle = getConditionStyle(
          ownerState?.inputProps?.className?.includes("date-picker-field"),
          {
            cursor: "pointer",
            "*": {
              cursor: "inherit",
            },
          }
        );

        return {
          ...datePickerFieldStyle,
          svg: {
            color: getProperTextColor(ownerState, theme),
          },
          input: {
            lineHeight: 1,
          },
        };
      },
    },
  },
};

export default components;

const getConditionStyle = (condition, style) => {
  return condition ? style : {};
};

const getProperTextColor = (ownerState, theme) => {
  const inputColor = ownerState?.error
    ? theme.palette.error.main
    : theme.palette.text.primary;
  const inputColorSvgOffset = ownerState?.disabled ? 0.9 : 0.4;

  return lighten(inputColor, inputColorSvgOffset);
};
