const nl = {
  messages: {
    error: {
      yup: {
        required: "Dit is een verplicht veld",
        email: "Dit is geen geldig e-mailadres",
      },
      api: {
        default: "Er is iets fout gegaan",
        403: "Er is iets fout gegaan",
        400: "Er klopt iets niet, controleer uw gegevens",
      },
    },
  },
  translation: {
    actions: {
      submit: "Indienen",
    },
    global: {
      personalData: "Persoonlijke Gegevens",
      matchData: "Wedstrijdgegevens",
      price: {
        main: "Prijs",
        text: "{{sign}} {{netPrice}} exclusief BTW. {{sign}} {{grossPrice}} inclusief BTW.",
      },
    },
    field: {
      placeholder: {
        club: "Selecteer Club",
        team: "Selecteer Team",
        date: {
          selectDateTime: "Selecteer Datum en Tijd",
        },
      },
      name: {
        main: "Naam",
        full: "Volledige Naam",
      },
      email: {
        main: "E-mail",
      },
      phone: {
        main: "Telefoonnummer",
      },
      clubAndTeam: {
        your: "Jouw Club en Team",
        home: "Thuis Club en Team",
        away: "Uit Club en Team",
      },
      date: {
        match: {
          start: "Starttijd Wedstrijd",
        },
      },
      field: {
        main: "Veld",
      },
      referee: {
        numberOfReferees: "Aantal Scheidsrechters",
        plural_counter_one: "{{count}} Scheidsrechter",
        plural_counter_other: "{{count}} Scheidsrechters",
      },
      comments: "Opmerkingen",
      acceptAgreement:
        "Ik accepteer de <accept_anchor>algemene voorwaarden</accept_anchor>",
    },
    refereeRequest: {
      title: "Scheidsrechter aanvragen",
      paragraph1:
        "Staat uw club niet bij de opties? Vul dan het formulier in bij '<quote_anchor>Offerte</quote_anchor>' en wij komen zo snel mogelijk bij u terug. Nog vragen? Kijk bij <faq_anchor>FAQ</faq_anchor> of neem contact met ons op.",
      paragraph2:
        "Na het invullen van het formulier krijgt u direct de mogelijkheid om via iDeal te betalen. Wanneer wij de betaling ontvangen hebben gaan wij aan de slag met uw aanvraag.",
      paragraph3:
        "Wij werken van week tot week. Let wel op dat wij alleen in de week voordat de wedstrijd plaatsvindt naar uw aanvraag zullen kijken.",
      paragraph4:
        "Mocht u geen bevestiging ontvangen dan kan het zijn dat de bevestiging in uw spam map is beland, dus houd ook dit goed in de gaten.",
      paragraph5:
        "Als u betaald en wij uw aanvraag niet kunnen honoreren, dan nemen wij contact met u op. Wij kunnen 95% van de aanvragen in behandeling nemen. Heeft u betaald en geen bericht van ons ontvangen binnen de boven gestelde termijnen dan volgt de update zo snel mogelijk.",
    },
    refereeRequestSuccess: {
      title: "Helemaal Mooi",
      paragraph1: "Je boeking is verzonden. Onze scheidsen accepteren boekingen doorgaans binnen 48 uur. Je ontvangt dan een mail met onderwerp \"Scheidsrechter bevestigd\".",
    },
  },
};

export default nl;
